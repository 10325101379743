import {useActions} from 'hooks/useActions';
import {useTypedSelector} from 'hooks/useTypedSelector';
import React, {lazy, Suspense, useEffect, useMemo, useState} from 'react';
import Alert from 'ui/material-kit/Alert';
import CustomModal from "../components/modal";
import Login from "../components/forms/Login";
import PrettyLoader from 'ui/loaders/PrettyLoader';
import {SocketProvider} from "../services/SocketService";
import {io} from "socket.io-client";

import {useTranslation} from "react-i18next";
import {getStorage} from "../hooks/useStorage";
import settings from "../settings";
import {getTvProviders} from "../store/actions/gameActions";
import ErrorModal from "../components/modal/ErrorModal";
import {useLocation} from "react-router-dom";
import {HighRoller} from 'features/high-roller/ui/high-roller';

const MobileApp = lazy(() => import('./mobile-app'))
const DesktopApp = lazy(() => import('./desktop-app'))

const RouterApp = () => {
    const {
        getUserData,
        getPromotions,
        getBanners,
        getProviders,
        getTvProviders,
        getCategories,
        setLoading,
        getStaticPages,
    } = useActions();
    const [isMobileDevice, setIsMobileDevice] = useState(false);
    const [init, setInit] = useState(false);
    const [socket, setSocket] = useState<any>(null);
    const {i18n} = useTranslation();
    const isLoginModal = getStorage("APP_OTHER.HEADER.LOGIN.VISIBLE");
    const {pathname} = useLocation();

    console.log("rendering... koora")
    const {loading, user} = useTypedSelector(state => {
        return ({
            user: state.user.user,
            loading: state.app.loading
        })
    });

    useEffect(() => {
        setLoading(true);
        setInit(false);
        if (typeof window.orientation !== 'undefined') {
            setIsMobileDevice(true);
        }
        Promise.all([
            getUserData(),
            getTvProviders(),
            getProviders(),
            getStaticPages(),
            getCategories()

        ]).then(() => {
            setInit(true);
            setLoading(false);

        });

    }, []);

    useEffect(() => {
        getBanners(i18n.language);
        getPromotions(i18n.language);
    }, [i18n.language]);


    useEffect(() => {
        const intervalCall = setInterval(() => {
            if (user) {
                getUserData();
            }

        }, 600000);

        return () => {
            clearInterval(intervalCall);
        }
    }, [user]);


    const {SETTINGS} = settings;
    const mirror = useMemo(() => Object.keys(SETTINGS.MIRRORS).find(item => item === window.location.host), [SETTINGS]);
    const BASE_URL = settings.NODE_ENV === "development" ? SETTINGS.API_DEV_URL : mirror ? SETTINGS.MIRRORS[`${mirror}`].API_PROD_URL :
        SETTINGS.API_PROD_URL;

    // @ts-ignore
    useEffect(() => {
        const newSocket = io(`${BASE_URL}`, {
            transports: ['websocket'],
            auth: {userId: user?.id ?? +new Date()}
        });
        setSocket(newSocket);
        return () => newSocket.close();
    }, [setSocket, user?.id]);


    const {OTHER} = SETTINGS;
    return (
        <>
            {isLoginModal && <CustomModal isDesktop={!isMobileDevice}>
                <Login isDesktop={!isMobileDevice}/>
            </CustomModal>}
            <ErrorModal isDesktop={!isMobileDevice}/>
            <Alert isDesktop={!isMobileDevice}/>
            <PrettyLoader isLoading={loading}/>
            <Suspense fallback={<PrettyLoader isLoading={true}/>}>
                {init && <SocketProvider socket={socket}>
                    {
                        isMobileDevice ? <MobileApp/> : <DesktopApp/>
                    }

                    {OTHER.HIGH_ROLLERS && (isLoginModal || !isLoginModal && user) && ![`/horses`, `/sport`, `/live-sport`, `/game/`].some(item => pathname.includes(item)) &&
                        <div style={{width: "100%", marginTop: 30}}>
                            <HighRoller/>
                        </div>}
                </SocketProvider>
                }
            </Suspense>
        </>
    );
};

export default RouterApp;
