import React from 'react';
import {ReactComponent as PromotionsIcon} from "assets/images/drawer_promotions_icon.svg"
import {ReactComponent as RulesIcon} from "assets/images/drawer_rules_icon.svg"
import {ReactComponent as LanguageIcon} from "assets/images/drawer_language_icon.svg"
import {ReactComponent as HelpIcon} from "assets/images/drawer_help_icon.svg"
import {ReactComponent as StarIcon} from "assets/images/hall_star_icon.svg"
import ListMenu from "ui/ListMenu";
import {useTypedSelector} from "hooks/useTypedSelector";
import {useTranslation} from "react-i18next";
import HelpModal from 'components/modal/HelpModal';
import {TawkService} from "../../../services/tawkService";
import {getStorage} from "../../../hooks/useStorage";
import {useActions} from "../../../hooks/useActions";
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import BeltImage from "assets/images/belt-background_img.png";


type DrawerContentTypes = {
    onCloseDrawer?: () => void
}

const DrawerContent = ({onCloseDrawer}: DrawerContentTypes): JSX.Element => {
    const {t, i18n} = useTranslation();
    const {pages, open} = useTypedSelector(state => {
        return {
            pages: state.app.pages,
            open: state.app.modal
        }
    })
    const APP_ACCESS = getStorage("APP_ACCESS");
    const HELP = getStorage("APP_OTHER.HEADER.MENU.HELP");
    const isVisible = HELP.VISIBLE;
    const isTawk = HELP.TAWK;
    const isFeedback = HELP.FEED_BACK;
    const {updateModal} = useActions();
    const navigate = useNavigate();
    return (
        <div>
            {APP_ACCESS.PROMOTIONS.VISIBLE &&
                <React.Fragment key={`promotions-item`}>
                    <ListMenu title={t(`common.navbar.nav_promotions`)} icon={<PromotionsIcon/>} href={"/promotions"}/>
                </React.Fragment>
            }
            {APP_ACCESS.STATIC_PAGES.VISIBLE && <div style={{maxHeight: 210, overflow: "auto"}}>
                {
                    pages.filter(item => item.lang === i18n.language).map((item, index) => (
                        <React.Fragment key={`drawer-list-${index}-${item.title}`}>
                            <ListMenu title={item.title} icon={<RulesIcon/>} href={`/static/${item.url}/${item.lang}`}/>
                        </React.Fragment>
                    ))
                }
            </div>}
            {
                <React.Fragment key={`language-item`}>
                    <ListMenu disabled={true} title={t(`common.info.language`)} icon={<LanguageIcon/>}
                              isLanguage={true}/>
                </React.Fragment>
            }
            {isVisible &&
                <React.Fragment key={`help-item`}>
                    <div onClick={() => {
                        {
                            isTawk && TawkService.toggleWidget()
                            isFeedback && updateModal(true)
                            isFeedback && console.log(1)
                        }
                    }
                    }>
                        <ListMenu title={t(`common.navbar.nav_help`)} icon={<HelpIcon/>}/>
                    </div>
                </React.Fragment>
            }
            {isFeedback && <HelpModal isDesktop={false} onClose={() => updateModal(false)} isOpen={open}/>}
            {APP_ACCESS.HALL_OF_FAME.VISIBLE && <DrawerItem style={{marginTop: 10}} onClick={() => {
                navigate("/hall-of-fame")
            }}>
                <BackgroundBelt/>
                <Text>
                    <StarIcon/>
                    {t('pages.hall_of_fame.title')}
                </Text>
            </DrawerItem>}
        </div>
    );
};

export default DrawerContent;

const DrawerItem = styled.div`
  position: relative;
  width: 100%;
  height: 48px;
  :active {
    scale: 0.98;
  }
`

const BackgroundBelt = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(${BeltImage}) no-repeat right;
  background-size: cover;
`
const Text = styled.div`
  position: absolute;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
  color: #4D2608;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 10px;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -50%);
`
