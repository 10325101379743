import {getStorage} from "hooks/useStorage";
import {MainMenuCard} from "../types/card.types";

import SportIcon from '../assets/sport_icon.svg';
import SportBackground from '../assets/sport_background.png';
import LiveIcon from '../assets/live_icon.svg';
import LiveBackground from '../assets/live_background.png';
import GamesIcon from '../assets/games_icon.svg';
import GamesBackground from '../assets/games_background.webp';
import AmaticIcon from '../assets/amatic_icon.png';
import AmaticBackground from '../assets/amatic_background.png';
import EzugiIcon from '../assets/ezugi_icon.png';
import EzugiBackground from '../assets/ezugi_background.png';
import PragmaticIcon from '../assets/pragmatic_icon.png';
import PragmaticBackground from '../assets/pragmatic_background.webp';
import TvGamesIcon from '../assets/tv-games_icon.svg';
import TvGamesBackground from '../assets/tv-games_background.webp';


export default Object.values(getStorage("APP_OTHER.NAVBAR")) as MainMenuCard[];

export const additionalCards = [
    {
        KEY: 'sport',
        height: '80%',
        width: '90%',
        gradientColor: 'radial-gradient(60.71% 62.2% at -2.08% 0%, rgba(184, 233, 16, 0.5) 0%, rgba(167, 208, 22, 0) 100%), linear-gradient(180deg, #141F44 9.15%, #070C1F 95.43%)',
        arrowColor: '#DBFF00',
        shadowArrow: 'drop-shadow(0px 2px 10px #35964B)',
        withLineBackground: false,
        background: SportBackground,
        icon: SportIcon,
    }, {
        KEY: 'live',
        height: '100%',
        width: '70%',
        gradientColor: 'radial-gradient(60.71% 62.2% at -2.08% 0%, rgba(249, 59, 90, 0.5) 0%, rgba(197, 0, 12, 0) 100%), linear-gradient(180deg, #141F44 9.15%, #070C1F 95.43%);',
        arrowColor: '#C5000C',
        shadowArrow: 'drop-shadow(0px 2px 10px #FF4264)',
        withLineBackground: false,
        background: LiveBackground,
        icon: LiveIcon,
    }, {
        KEY: 'games',
        height: '100%',
        width: '80%',
        gradientColor: 'radial-gradient(102.7% 240.1% at 6.07% -2.7%, #182598 0%, rgba(20, 24, 85, 0) 46.72%)',
        arrowColor: '#0066FF',
        shadowArrow: 'drop-shadow(0px 2px 10px rgba(0, 112, 255, 0.7))',
        withLineBackground: false,
        background: GamesBackground,
        icon: GamesIcon,
    }, {
        KEY: 'amatic',
        height: '80%',
        width: '100%',
        gradientColor: 'radial-gradient(150.61% 283.7% at 26.49% -30.67%, #6B6301 0%, rgba(104, 96, 3, 0.614137) 12.81%, rgba(103, 95, 4, 0.440064) 20.1%, rgba(102, 95, 5, 0.32448) 27.4%, rgba(100, 93, 6, 0) 46.72%)',
        arrowColor: '#FF9901',
        shadowArrow: 'drop-shadow(0px 2px 10px rgba(255, 158, 1, 0.7))',
        withLineBackground: true,
        background: AmaticBackground,
        icon: AmaticIcon,
    },
    {
        KEY: 'ezugi',
        height: '80%',
        width: '100%',
        gradientColor: 'radial-gradient(150.61% 283.7% at 26.49% -30.67%, #6B6301 0%, rgba(104, 96, 3, 0.614137) 12.81%, rgba(103, 95, 4, 0.440064) 20.1%, rgba(102, 95, 5, 0.32448) 27.4%, rgba(100, 93, 6, 0) 46.72%)',
        arrowColor: '#FF9901',
        shadowArrow: 'drop-shadow(0px 2px 10px rgba(255, 158, 1, 0.7))',
        withLineBackground: true,
        background: EzugiBackground,
        icon: EzugiIcon,
    },
    {
        KEY: 'pragmatic',
        height: '80%',
        width: '70%',
        gradientColor: 'radial-gradient(150.61% 283.7% at 26.49% -30.67%, #6B6301 0%, rgba(104, 96, 3, 0.614137) 12.81%, rgba(103, 95, 4, 0.440064) 20.1%, rgba(102, 95, 5, 0.32448) 27.4%, rgba(100, 93, 6, 0) 46.72%)',
        arrowColor: '#FF9901',
        shadowArrow: 'drop-shadow(0px 2px 10px rgba(255, 158, 1, 0.7))',
        withLineBackground: true,
        background: PragmaticBackground,
        icon: PragmaticIcon,

    }, {
        KEY: 'tv-games',
        height: '100%',
        width: '100%',
        gradientColor: 'linear-gradient(150.87deg, #E909B2 -36.46%, rgba(234, 8, 178, 0) 30.38%);',
        arrowColor: '#962BD8',
        shadowArrow: 'drop-shadow(0px 2px 10px #BE1BC6)',
        withLineBackground: true,
        background: TvGamesBackground,
        icon: TvGamesIcon,
    }
]
