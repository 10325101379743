import React, {useRef} from 'react';
import {ReactComponent as ArrowIcon} from "assets/images/game_arrow_icon.svg";
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import useOutsideClick from 'hooks/useOutsideClick';
import styled from 'styled-components';
import {getStorage} from 'hooks/useStorage';

type LanguageControllerProps = {
    isDesktop?: boolean;
}

const LanguageController = (props: LanguageControllerProps) => {
    const [open, setOpen] = useState<boolean>(false);

    const {t, i18n} = useTranslation();
    const languages = getStorage("APP_OTHER.LANGUAGES");
    let languagesArr: any = Object.values(languages).filter((item: any) => item.VISIBLE);


    const refDesk = useRef(null);
    const refMob = useRef(null);
    useOutsideClick(refMob, () => {
        setOpen(false)
    })
    useOutsideClick(refDesk, () => {
        setOpen(false)
    })

    const changeLanguage = async (language: string) => {
        await i18n.changeLanguage(language);
    };

    const handleClick = () => {
        setOpen(!open)
    }
    const languageData: { title: string, icon: string }[] = languagesArr.map((item: any) => ({
        title: item.KEY,
        icon: require(`../../${item.ICON_PATH}`)
    }));


    const curLang = languageData.find(item => item.title === i18n.language);
    if (props.isDesktop) {
        return (
            <DesktopButton>
                <div style={{position: "relative"}} ref={refDesk}>
                    <LanguageBtnDesktop
                        onClick={handleClick}>
                        <img style={{width: 23, height: 23}} src={curLang?.icon} alt={curLang?.title}/>
                        <ArrowSvg>
                            <ArrowIcon/>
                        </ArrowSvg>
                    </LanguageBtnDesktop>

                    {
                        open && <StyledMenuDesktop
                            id="desktop-positioned-menu"
                        >
                            {
                                languageData.sort((a, b) => (+a.title.includes(i18n.language) - +b.title.includes(i18n.language))).reverse().map((item, index) => (
                                    <React.Fragment key={index + "lang" + item.title}>
                                        <StyledMenuItemDesktop onClick={async () => {
                                            await changeLanguage(item.title);
                                            handleClick();
                                        }}>
                                            <img style={{width: 23, height: 23}} src={item?.icon} alt={item?.title}/>
                                        </StyledMenuItemDesktop>
                                    </React.Fragment>
                                ))
                            }

                        </StyledMenuDesktop>
                    }

                </div>

            </DesktopButton>

        )
    } else {
        return (
            <>
                <div style={{position: "relative"}} ref={refMob}>
                    <LanguageBtn
                        id="demo-positioned-button"
                        onClick={handleClick}
                    >

                        <img src={curLang?.icon} alt={curLang?.title}/>
                        <Text>{curLang?.title}</Text>
                    </LanguageBtn>

                    {open &&
                        <StyledMenu
                            id="mobile-positioned-menu"
                        >

                            {
                                languageData.sort((a, b) => (+a.title.includes(i18n.language) - +b.title.includes(i18n.language))).reverse().map((item, index) => (
                                    <React.Fragment key={index + "lang" + item.title}>
                                        <StyledMenuItem onClick={async () => {
                                            await changeLanguage(item?.title);
                                            handleClick();
                                        }}>
                                            <img src={item?.icon} alt={item?.title}/>
                                            <Text>{item?.title}</Text>
                                        </StyledMenuItem>
                                    </React.Fragment>
                                ))
                            }


                        </StyledMenu>
                    }


                </div>
            </>

        );
    }
};

export default LanguageController;


const DesktopButton = styled('div')((props) => ({
    padding: "0 13px",
    height: 50,
    borderRadius: "5px 0 0 5px",
    fontFamily: "Roboto Condensed",
    color: "white",
    textTransform: "uppercase",
    fontSize: 24,
    display: "flex",
    alignItems: "center",
    background: props.theme.palette.primary.main,
}))

const LanguageBtn = styled("div")((props) => ({
    minWidth: 50,
    maxWidth: 50,
    boxSizing: "border-box",
    height: 27,
    background: "#ffffff",
    cursor: "pointer",
    textTransform: "uppercase",
    borderRadius: 5,
    display: "flex",
    padding: "0 8px",
    justifyContent: "space-between",
    alignItems: "center",
    color: "black",
    "&:hover": {
        background: "#c0c0c0",
    }
}))

const ArrowSvg = styled("div")((props) => ({
    transform: "rotate(90deg)",
    "& > svg > path": {
        fill: "white",
    }
}))

const LanguageBtnDesktop = styled("button")((props) => ({
    background: "transparent",
    display: "flex",
    cursor: "pointer",
    gap: 7,
    alignItems: "center",
    "& > svg": {
        width: 23,
        height: 23
    }
}))


const StyledMenuDesktop = styled("div")((props) => ({
    zIndex: 1000000,
    backgroundColor: props.theme.palette.primary.main,
    padding: 0,
    top: -5,
    position: "absolute"
}))


const StyledMenuItemDesktop = styled("div")((props) => ({
    color: "#000000",
    display: "flex",
    cursor: "pointer",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 40,
    padding: "0 8px",
    "& > svg": {
        width: 23,
        height: 23
    }
}))

const StyledMenuItem = styled("div")((props) => ({
    color: "#000000",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 24,
    padding: "0 8px",
    textTransform: "uppercase",
}))

const Text = styled('div')((props) => ({
    color: "#000000",
    fontSize: 13,
    fontWeight: 700,
    textTransform: "capitalize"
}))

const StyledMenu = styled("div")((props) => ({
    position: "absolute",
    backgroundColor: "#ffffff",
    color: "black",
    width: 50,
    zIndex:1000,
    borderRadius: 5,
    top: 0
}))
