import {gamesAPI} from "api/games/gamesApi";
import {store} from "../../store";

const {dispatch} = store;

interface IOpenGame {
    providerId?: string,
    isDesktop?: boolean,
    isSport?: boolean,
    sportType?: string,
    gameId?: string,
    setLoading: (bool: boolean) => void
}

const openGame = ({providerId, gameId, isSport, sportType, setLoading, isDesktop}: IOpenGame) => {
    let prevUrl = window.location.href;
    if (!isSport) {
        localStorage.setItem("previousPage", prevUrl);
        window.location.href = `/game/${providerId}/${gameId}`;
        return
    }
    setLoading(true);

    if (!sportType) {
        return
    }
    gamesAPI.getSportToken().then(x => {
        let url = ""
        if (sportType === "live") {
            url = "https://sport-frame.koora365.tn/Sport/live"
        } else {
            url = "https://sport-frame.koora365.tn/Sport/sport"
        }
        window.location.href = `${url}?token=${x.token}`;
        setLoading(false);
    }).catch(error => {
        setLoading(false);
        let {data} = error.response;
    })


};

export default openGame;
