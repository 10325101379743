import Games from "pages/Games";
import GamesDesktop from "pages/Games.desktop";
import Provider from "pages/Provider";
import Game from "../pages/Game";
import React from "react";
import TvGames from "pages/TvGames";
import TvGamesDesktop from "pages/TvGames.desktop";
import Horses from "../pages/Horses";
import Sport from "../pages/Sport";
import Promotions from "../pages/Promotions";
import StaticPage from "../pages/StaticPage";
import Profile from "../pages/profile";
import PersonalData from "../pages/profile/PersonalData";
import BetHistoryDesktop from "../pages/profile/BetHistory.desktop";
import PersonalDataDesktop from "../pages/profile/PersonalData.desktop";
import BetHistory from "../pages/profile/BetHistory";
import CashbackHistory from "../pages/profile/CashbackHistory";
import CashbackHistoryDesktop from "../pages/profile/CashbackHistory.desktop";
import OperationHistoryDesktop from "../pages/profile/OperationHistory.desktop";
import OperationHistory from "pages/profile/OperationHistory";
import SettingsDesktop from "../pages/profile/Settings.desktop";
import HallOfFame from "../pages/HallOfFame";
import HallOfFameDesktop from "../pages/HallOfFame.desktop";
import Settings from "../pages/profile/Settings";
import {RoutingList} from "constants/types";
import MainDesktop from "../pages/Main.desktop";
import Main from "../pages/Main";
import {ReactComponent as SportsIcon} from "assets/images/footer_sports_icon.svg";
import {ReactComponent as LiveSportsIcon} from "assets/images/footer_live-sports_icon.svg";
import {ReactComponent as GamesIcon} from "assets/images/footer_games_icon.svg";
import {ReactComponent as TvGamesIcon} from "assets/images/footer_tv-games_icon.svg";
import {ReactComponent as HorsesIcon} from "assets/images/footer_horses_icon.svg";
import {ReactComponent as MainIcon} from "assets/images/footer_main_icon.svg";
import {ReactComponent as PersonIcon} from "assets/images/profile_personal-data_icon.svg";
import {ReactComponent as CasinoBetHistoryIcon} from "assets/images/profile_casino-bet-history_icon.svg";
import {ReactComponent as CashBackHistoryIcon} from "assets/images/profile_cashback-history_icon.svg";
import {ReactComponent as CashBackIcon} from "assets/images/profile_cashback-icon.svg";
import {ReactComponent as HistoryIcon} from "assets/images/profile_history-of-operation_icon.svg";
import {ReactComponent as ChangePasswordIcon} from "assets/images/profile_change-password_icon.svg";
import CashbackDesktop from "pages/profile/Cashback.desktop";
import Cashback from "pages/profile/Cashback";


const routing: RoutingList[] = [

    { //Порядок обязатльный перед LOGIN_PAGE
        key: 'MAIN',
        lang_key: 'main',
        to: '/',
        component: <MainDesktop/>,
        mobileComponent: <Main/>,
        isAuth: false,
        iconJsx: <MainIcon/>
    },
    {
        key: 'GAMES',
        lang_key: 'games',
        to: '/games',
        component: <GamesDesktop/>,
        mobileComponent: <Games/>,
        isAuth: false,
        iconJsx: <GamesIcon/>
    },
    {
        key: 'PROVIDER_GAMES',
        lang_key: 'games',
        to: '/provider/:providerId/games',
        mobileComponent: <Provider/>,
        isAuth: false
    },
    {
        key: 'GAME',
        lang_key: 'game',
        to: '/game/:providerId/:gameId',
        component: <Game isDesktop/>,
        mobileComponent: <Game/>,
        isAuth: true
    },
    {
        key: 'TV_GAMES',
        lang_key: 'tv_games',
        to: '/tv-games',
        component: <TvGamesDesktop/>,
        mobileComponent: <TvGames/>,
        isAuth: false,
        iconJsx: <TvGamesIcon/>
    },
    {
        key: 'HORSES',
        lang_key: 'horses',
        to: '/horses',
        component: <Horses isDesktop/>,
        mobileComponent: <Horses/>,
        isAuth: true,
        iconJsx: <HorsesIcon/>
    },
    {
        key: 'SPORT',
        lang_key: 'sport',
        to: '/sport',
        component: <Sport type={"sport"} isDesktop/>,
        mobileComponent: <Sport type={"sport"}/>,
        isAuth: false,
        iconJsx: <SportsIcon/>
    },
    {
        key: 'LIVE_SPORT',
        lang_key: 'live_sport',
        to: '/live-sport',
        component: <Sport type={"live-sport"} isDesktop/>,
        mobileComponent: <Sport type={"live-sport"}/>,
        isAuth: false,
        iconJsx: <LiveSportsIcon/>
    },
    {
        key: 'PROMOTIONS',
        lang_key: 'promotions',
        to: '/promotions',
        component: <Promotions isDesktop/>,
        mobileComponent: <Promotions/>,
        isAuth: false
    },
    {
        key: 'STATIC_PAGES',
        lang_key: 'static_pages',
        to: '/static',
        component: <StaticPage/>,
        mobileComponent: <StaticPage/>,
        isAuth: false
    },
    {
        key: 'PROFILE',
        lang_key: 'profile',
        to: '/profile',
        mobileComponent: <Profile/>,
        isAuth: true
    },
    {
        key: 'PROFILE_PERSONAL_DATA',
        lang_key: 'personal_data',
        to: '/profile/personal-data',
        component: <PersonalDataDesktop/>,
        mobileComponent: <PersonalData/>,
        isAuth: true,
        iconJsx: <PersonIcon/>
    },
    {
        key: 'PROFILE_BET_HISTORY',
        lang_key: 'bet_history',
        to: '/profile/bet-history',
        component: <BetHistoryDesktop/>,
        mobileComponent: <BetHistory/>,
        isAuth: true,
        iconJsx: <CasinoBetHistoryIcon/>
    },
    {
        key: 'PROFILE_CASHBACK',
        lang_key: 'cashback',
        to: '/profile/cashback',
        component: <CashbackDesktop/>,
        mobileComponent: <Cashback/>,
        isAuth: true,
        iconJsx: <CashBackIcon/>
    },
    {
        key: 'PROFILE_CASHBACK_HISTORY',
        lang_key: 'cashback_history',
        to: '/profile/cashback-history',
        component: <CashbackHistoryDesktop/>,
        mobileComponent: <CashbackHistory/>,
        isAuth: true,
        iconJsx: <CashBackHistoryIcon/>
    },
    {
        key: 'PROFILE_OPERATION_HISTORY',
        lang_key: 'history_of_operations',
        to: '/profile/operation-history',
        component: <OperationHistoryDesktop/>,
        mobileComponent: <OperationHistory/>,
        isAuth: true,
        iconJsx: <HistoryIcon/>
    },
    {
        key: 'PROFILE_SETTINGS',
        lang_key: 'change_password',
        to: '/profile/settings',
        component: <SettingsDesktop/>,
        mobileComponent: <Settings/>,
        isAuth: true,
        iconJsx: <ChangePasswordIcon/>
    },
    {
        key: 'HALL_OF_FAME',
        lang_key: 'hall_of_fame',
        to: '/hall-of-fame',
        component: <HallOfFameDesktop/>,
        mobileComponent: <HallOfFame/>,
        isAuth: false,
        iconJsx: <ChangePasswordIcon/>
    }

]
export default routing
