import React, {useEffect, useState} from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import styled from 'styled-components';
import {useSearchParams} from 'react-router-dom';
import GameSwitcher from "components/gameSwitcher";
import ProviderCards from "components/cards/providerCards";
import CustomSearch from "ui/CustomSearch";
import {useDebounce} from "hooks/useDebounce";
import {MobileContainer} from 'ui/containers';
import {useActions} from "hooks/useActions";
import {useTypedSelector} from "hooks/useTypedSelector";
import GameCard from "ui/GameCard";
import useDimensions from "react-cool-dimensions";
import openGame from "helpers/gamesHelper/openGame";
import {useTranslation} from 'react-i18next';
import {getStorage} from "../hooks/useStorage";

const TvGames = (): JSX.Element => {
    const {t} = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [initialize, setInitialize] = useState(true);
    const [selectProviderCard, setSelectProviderCard] = useState<string>("");
    const [titleGame, setTitleGame] = useState("");
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [page, setPage] = useState(1);
    const [isFirstLoading, setIsFirstLoading] = useState(false);
    const debouncedSearchTerm = useDebounce(titleGame, 500);
    const {getTvGames, toggleFavoriteGames, updateAlert, setLoading} = useActions();
    const {observe, width} = useDimensions({});
    const TvGamesAccess = getStorage("APP_ACCESS")["TV_GAMES"];

    const {user, tvGames, tvGamesCount,tvProviders} = useTypedSelector((state) => {
        return {
            user: state.user.user,
            isLoading: state.game.loading,
            tvGamesCount: state.game.tvGamesCount,
            tvProviders: state.game.tvProviders,
            tvGames: state.game.tvGames.toArray().map(item => item[1]),
        }
    })


    useEffect(() => {
        if (initialize) {
            let params = {
                provider: searchParams.get("provider"),
                title: searchParams.get("title")
            }
            let isSearch = Object.values(params).some(item => item !== null);
            if (isSearch) {
                setSelectProviderCard((params.provider === null || !tvProviders.find(item => (item === params.provider))) ? "" : params.provider);
                setTitleGame(params.title === null ? "" : params.title);
            }
            getTvGames({
                title: params.title === null ? "" : params.title,
                action: "update",
                provider: params.provider === null ? selectProviderCard : params.provider,
            });
            setInitialize(false);
        } else {
            setIsFirstLoading(true);
            handleSearch().then(() => {
                setPage(1);
                setHasMoreItems(true);
                setIsFirstLoading(false);
            })
        }
    }, [debouncedSearchTerm, selectProviderCard])


    const handleSearch = async () => {
        await getTvGames({
            title: debouncedSearchTerm,
            action: "update",
            provider: selectProviderCard,
        });
    }

    const loadMore = () => {
        if (tvGames.length >= tvGamesCount) {
            setHasMoreItems(false);
            return;
        }
        setHasMoreItems(true);
        let newPage = page + 1;
        setPage(newPage);
        getTvGames({
            title: titleGame,
            provider: selectProviderCard,
            action: "add",
            page: newPage
        })
    };

    return (
        <>
            <GameSwitcher/>
            <ProviderCards isTvProvider selectProvider={selectProviderCard}
                           setSelectProvider={(val: string) => {
                               setSearchParams({...Object.fromEntries(searchParams), provider: val});
                               setSelectProviderCard(val)
                           }} providers={tvProviders}/>
            <div style={{margin: "10px 5px"}}>
                <CustomSearch value={titleGame} OnCloseClick={() => {
                    setTitleGame("");
                    setSearchParams({...Object.fromEntries(searchParams), title: ""});
                }} onChange={(e) => {
                    setSearchParams({...Object.fromEntries(searchParams), title: e.target.value});
                    setTitleGame(e.target.value)
                }} placeholder={`${t(`common.options.search_game`)}`}/>
            </div>
            <MobileContainer ref={observe}>

                {isFirstLoading || tvGames.length === 0 ? null :
                    <InfiniteScroll
                        dataLength={tvGames.length}
                        next={loadMore}
                        hasMore={hasMoreItems}
                        loader={<></>}
                    >
                        <ContainerTvGames>
                            {
                                tvGames.map((item, index) => (
                                    <React.Fragment key={`${index}-tvgame-${item.Id}`}>
                                        <GameCard
                                            isTvGame
                                            TvGameWidth={`${width / 2 - 4}px`}
                                            TvGameHeight={`14vh`}
                                            favoriteClick={(e) => {
                                                if (!user) {
                                                    updateAlert({
                                                        isOpen: true,
                                                        status: "error",
                                                        message: `${t(`common.alert.text_error`)}`
                                                    });
                                                } else {
                                                    e.stopPropagation();
                                                    toggleFavoriteGames(item.Id, false, true);
                                                }
                                            }} isFavorite={item.IsFavorite} url={item.Thumbnail} onClick={() => {
                                            if (!user) {
                                                updateAlert({
                                                    isOpen: true,
                                                    status: "error",
                                                    message: `${t(`common.alert.text_error`)}`
                                                });
                                            } else {
                                                openGame({
                                                    providerId: item.SectionId,
                                                    gameId: item.Id,
                                                    setLoading: (bool: boolean) => {
                                                        setLoading(bool);
                                                    }
                                                })
                                            }
                                        }}/>
                                    </React.Fragment>
                                ))
                            }
                        </ContainerTvGames>

                    </InfiniteScroll>
                }

            </MobileContainer>
        </>
    );
};

export default TvGames;


const ContainerTvGames = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  flex-flow: wrap;
  width: 100%;
`

