import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useLocation, useParams} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {useActions} from "../hooks/useActions";
import DesktopGame from "../components/games/DesktopGame";

type GameProps = {
    isDesktop?: boolean
}

const Game = (props: GameProps): JSX.Element => {
    const {isDesktop} = props;
    const {t} = useTranslation();
    const [dataGame, setDataGame] = useState("");
    const [gameInfo, setGameInfo] = useState<any>({});
    const location = useLocation();
    const {i18n} = useTranslation();
    const {gameId, providerId} = useParams();

    const {updateError, setLoading} = useActions();

    useEffect(() => {
        let prevPage = localStorage.getItem("previousPage");
        if (!prevPage) {
            prevPage = window.origin;
        }
        setLoading(true);
        if (props.isDesktop) {
            axios.get(`/api/info/game/${gameId}`).then(d => {
                setGameInfo(d.data);
            })
        };
        axios.post(`/api/json-rpc/create-session`, {
            GameId: gameId,
            ProviderId: providerId,
            ReturnUrl: prevPage,
            Device: isDesktop ? "desktop" : "mobile",
            Lang: i18n.language
        }).then(d => {
            setDataGame(d.data.SessionUrl);
            setLoading(false);
        }, (error) => {
            if (error.response.data.code === 265) {
                updateError({isOpen: true, message: t("common.errors.this_provider_is_not_available_in_your_country")})
            } else {
                updateError({isOpen: true, message: t("common.errors.something_went_wrong")})
            }

        })
    }, [location.pathname]);


    if (!props.isDesktop) {
        return (<ContainerMobile>
            <Iframe src={dataGame} frameBorder={0} allowFullScreen={true}
                    height={"100%"} width={"100%"}/>
        </ContainerMobile>)
    } else {
        return (<DesktopGame gameInfo={gameInfo} dataGame={dataGame}/>)
    }

};

export default Game;


const ContainerMobile = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Iframe = styled.iframe`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;
