import React, {useEffect, useState, useRef} from 'react';
import styled from "styled-components";
import {useInput} from "hooks/useInput";
import AccordionSelect from 'ui/AccordionSelect';
import {useTypedSelector} from "hooks/useTypedSelector";
import DesktopGame from "ui/DesktopGame";
import {useActions} from "hooks/useActions";
import {useDebounce} from "hooks/useDebounce";
import InfiniteScroll from 'react-infinite-scroll-component';
import openGame from "helpers/gamesHelper/openGame";
import {useTranslation} from 'react-i18next';
import TextField from "../ui/material-kit/TextField";
import SearchIcon from "../assets/images/input_search_icon.svg";
import {useSearchParams} from "react-router-dom";
import useDimensions from "react-cool-dimensions";

const GamesDesktop = (): JSX.Element => {
    const [providerName, setProviderName] = useState("");
    const {t, i18n} = useTranslation();
    const [initialize, setInitialize] = useState(true);
    const [category, setCategory] = useState("");
    const [titleGame, setTitleGame] = useState("");
    const searchProvider = useInput('');
    const {getGameList, toggleFavoriteGames, updateAlert, setLoading} = useActions();
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [page, setPage] = useState(0);
    const debouncedSearchTerm = useDebounce(titleGame, 500);
    const [searchParams, setSearchParams] = useSearchParams();
    const refScroll = useRef(null)

    useEffect(() => {
        if (initialize) {
            let params = {
                provider: searchParams.get("provider"),
                title: searchParams.get("title"),
                category: searchParams.get("category"),
            }
            let isSearch = Object.values(params).some(item => item !== null);
            if (isSearch) {
                let categoriesContent = categories.find(item => item.content.some(con => con.title === params.category));

                setCategory((params.category === null || !categoriesContent) ? "all" : categoriesContent.content.find(con => con.lang === i18n.language)?.title || "all");
                setProviderName((params.provider === null || !getProviders().find(item => (item.title === params.provider))) ? "" : params.provider);
                setTitleGame(params.title === null ? "" : params.title);
            }
            getGameList({
                category: params.category === null ? "all" : params.category,
                title: params.title === null ? "" : params.title,
                size: 30,
                provider: params.provider === null ? providerName : params.provider,
                action: "update"
            });

            setInitialize(false);
        } else {
            window.scrollTo({
                top: 0,
            })
            getGameList({
                category: category === "" ? "all" : category,
                title: debouncedSearchTerm,
                size: 30,
                provider: providerName,
                action: "update"
            });

        }
        setHasMoreItems(true);
        setPage(1);

    }, [debouncedSearchTerm, category, providerName]);


    const {
        user,
        gameList,
        categories,
        gameListCount,
        providersList,
        categoriesList,
        getProviders
    } = useTypedSelector((state) => {
        return {
            user: state.user.user || undefined,
            providersList: state.game.providers.map((item) => ({
                title: item, onClick: () => {
                    if (item === providerName) {
                        setProviderName("");
                        setSearchParams({...Object.fromEntries(searchParams), provider: ""});
                    } else {
                        setSearchParams({...Object.fromEntries(searchParams), provider: item});
                        setProviderName(item);
                    }
                },
                isSelect: item === providerName,
            })),
            categories: state.game.categories,
            categoriesList: state.game.categories.filter(cat => cat.content.some(it => it.lang === i18n.language)).map((item) => {
                let titleCategories = item.content.find(item => item.lang === i18n.language)?.title || "";
                return ({
                    title: titleCategories,
                    img: item.img_url,
                    onClick: () => {
                        if (titleCategories === category) {
                            setCategory("");
                            setSearchParams({...Object.fromEntries(searchParams), category: ""});
                        } else {
                            setCategory(titleCategories);
                            setSearchParams({...Object.fromEntries(searchParams), category: titleCategories});
                        }

                    },
                    isSelect: item.id === (state.game.categories.find(itemm => itemm.content.some(it => it.title === category))?.id),
                })
            }),
            gameList: state.game.gameList.toArray().map(item => {
                return {
                    id: item[1].Id,
                    providerId: item[1].SectionId,
                    name: item[1].Name,
                    url: item[1].Thumbnail,
                    isFavorite: item[1].IsFavorite,
                }

            }),
            gameListCount: state.game.gameListCount,
            getProviders: () => {
                return providersList.filter(item => item.title.toLocaleLowerCase().includes(searchProvider.value.toLocaleLowerCase()))
            }
        }
    })


    const handleCallback = (): boolean => {
        if (!user) {
            updateAlert({isOpen: true, status: "error", message: `${t(`common.alert.text_error`)}`});
            return false;
        } else return true;
    }


    const loadMore = () => {
        if (gameList.length >= gameListCount) {
            setHasMoreItems(false);
            return;
        }
        setHasMoreItems(true);
        let newPage = page + 1;
        setPage(newPage);
        getGameList({
            title: debouncedSearchTerm,
            provider: providerName,
            size: 30,
            category: category === "" ? "all" : category,
            action: "add",
            page: newPage
        })

    };

    const {observe, height, width} = useDimensions({});

    return (
        <Wrapper>
            <StickyContainer>
                <CategoryContainer>
                    <InputContainer>
                        <InputContainerAbsolute>
                            <TextField placeholder={`${t(`common.options.search_game`)}`} rightIcon={{
                                url: SearchIcon,
                                extra: `width: 16px; height: 16px;`
                            }} extra={`height: 43px; font-size: 18px`}
                                       value={titleGame} onChange={(e) => {
                                setSearchParams({...Object.fromEntries(searchParams), title: e.target.value});
                                setTitleGame(e.target.value)
                            }}/>
                        </InputContainerAbsolute>
                    </InputContainer>
                    <div/>
                    <InputContainer>
                        <InputContainerAbsolute>
                            <AccordionSelect
                                list={categoriesList}
                                isImage={true}
                                title={`${category === "" ? `${t(`common.options.category_filter`)}` : category}`}/>
                        </InputContainerAbsolute>
                    </InputContainer>
                    <div/>
                    <InputContainer>
                        <InputContainerAbsolute>
                            <AccordionSelect {...searchProvider}
                                             list={getProviders()}
                                             isImage={false}
                                             title={`${providerName === "" ? `${t(`common.options.provider_filter`)}` : providerName}`}
                                             withSearch/>
                        </InputContainerAbsolute>
                    </InputContainer>
                </CategoryContainer>
            </StickyContainer>

            <GameContainer ref={refScroll}>
                <div ref={observe} style={{height: "100%", width: "100%"}}>

                    <InfiniteScroll
                        dataLength={gameList.length}
                        next={loadMore}
                        hasMore={hasMoreItems && gameList.length !== 0}
                        loader={<></>}
                    >
                        <GameContainerStyled>
                            {
                                gameList.map((item, index) => (
                                    <React.Fragment key={`game-desktop-${index}-${item.id}`}>
                                        <GamingContainer>
                                            <DesktopGame isSmall
                                                         width={(index !== 0 && index !== 17) ? (width - 80) / 7 : 2 * ((width - 40) / 7)}
                                                         height={(index !== 0 && index !== 17) ? (width - 80) / 7 : 2 * ((width - 40) / 7)}
                                                         isFavorite={item.isFavorite}
                                                         name={item.name}
                                                         provider={item.providerId}
                                                         onFavoriteClick={() => {
                                                             if (handleCallback()) {
                                                                 toggleFavoriteGames(item.id, true);
                                                             }
                                                         }}
                                                         url={item.url}
                                                         onClick={() => {
                                                             if (handleCallback()) {
                                                                 openGame({
                                                                     isDesktop: true,
                                                                     providerId: item.providerId,
                                                                     gameId: item.id,
                                                                     setLoading: (bool: boolean) => {
                                                                         setLoading(bool);
                                                                     }
                                                                 })
                                                             }
                                                         }}/>
                                        </GamingContainer>
                                    </React.Fragment>
                                ))
                            }
                        </GameContainerStyled>

                    </InfiniteScroll>
                </div>
            </GameContainer>

        </Wrapper>
    );
};

export default GamesDesktop;

const GameContainerStyled = styled.div`
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 2fr;

`

const GamingContainer = styled.div`
  position: relative;
  display: grid;
  border-radius: 10px;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0px, 0px, 0px);

  :nth-child(1) {
    display: block;
    grid-area: 1 / 1 / 3 / 3;
  }

  :nth-child(18) {
    grid-area: 3 / 6 / 5 / 8;
  }
`

const Wrapper = styled.div`
  margin: 20px 80px 0 80px;
`

const CategoryContainer = styled.div`
  position: relative;
  background-color: ${props => props.theme.palette.primary.dark};
  width: 100%;
  display: flex;
  height: 60px;
  justify-content: space-around;
  align-items: center;
`

const StickyContainer = styled.div`
  top: 0;
  position: sticky;
  width: 100%;
  z-index: 2000;
  background-color: ${props => props.theme.palette.primary.dark};
`

const GameContainer = styled.div`
  height: 100%;
  background-color: ${props => props.theme.palette.primary.dark};
  width: 100%;

`
const InputContainer = styled.div`
  width: 300px;
  height: 43px;
  position: relative;
`
const InputContainerAbsolute = styled(InputContainer)`
  position: absolute;
  z-index: 9999;
`
